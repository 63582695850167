

















import { Component, Vue, Prop } from 'vue-property-decorator';

import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import VideoThumb from '@/components/VideoThumb/index.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';
import ExLoading from '@/globalComponents/ExLoading/index.vue';

import HistoricService from '@/services/Historic/historicService';

import { LIST_NAME_CONTEXT } from '@/constant/Context';
import { LIST_PLAN_ID } from '@/constant/PlansIDs';
import { VideosHistoric } from '@/globalInterfaces/Video';

@Component({
  components: {
    VideoThumb,
    BoxContainer,
    FeedbackUser,
    ExLoading
  }
})
export default class LastVideo extends Vue {
  @Prop({ default: LIST_NAME_CONTEXT.BOOK }) context!: string;

  private isLoading = true;
  private video: Record<string, any> = {};

  private HistoricService = new HistoricService();

  async mounted() {
    this.getLastVideoWatched();
  }

  get plan() {
    return this.$store.getters.profile?.plan;
  }

  // get isPlanFree() {
  //   return this.plan?.planID === LIST_PLAN_ID.FREE;
  // }

  async getLastVideoWatched() {
    try {
      this.isLoading = true;

      const video = await this.HistoricService.getLastVideoWatched({
        context: this.context
        // isFree: this.isPlanFree,
      });

      if (video) {
        this.video = video;
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar a última aula assistida.',
        status: 'error'
      });

      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  redirectToVideo(videoData: VideosHistoric) {
    if (!videoData) return;

    const {
      context, path, idTopic, slug 
    } = videoData.object;

    this.$router.push({
      name: 'videos',
      params: {
        context,
        path,
        topicID: idTopic,
        fileID: String(videoData.idobject),
        slugFile: slug
      }
    });
  }
}
